<template>
<div class="config-device-container">
  <Form ref="form" :model="form" :label-width="200">
    <FormItem prop="energyEnable" label="是否启用每日能耗计算">
      <i-switch v-model="form.energyEnable" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
        <span slot="open">{{showLang('com.state.enable')}}</span>
        <span slot="close">{{showLang('com.state.disable')}}</span>
      </i-switch>
    </FormItem>
    <FormItem prop="energyPrice" label="能耗电价">
      <Input type="number" v-model="form.energyPrice" style="width: 200px; margin-right:10px">
        <span slot="append">元/度</span>
      </Input>
    </FormItem>
    <FormItem prop="energyPrice" label="能耗统计偏移小时数">
      <Input type="number" v-model="form.energyOffset" style="width: 200px; margin-right:10px">
        <span slot="append">小时</span>
      </Input>
    </FormItem>
    <FormItem prop="energyGroups" label="能耗统计分区设置">
      <div class="energy-group-item" style="border-bottom: solid 1px #dcdee2; padding-bottom: 5px;">
        <div class="group-index">序号</div>
        <div class="group-content" style="text-align: center;">
          分区名称
        </div>
        <div class="group-btns" style="text-align: center;">
          <Button type="primary" @click="add">新增</Button>
        </div>
      </div>
      <div class="energy-group-list">
        <div class="energy-group-item" v-for="(group, idx) in form.groups" :key="idx">
          <div class="group-index">{{ idx + 1 }}、</div>
          <div class="group-content">
            <Select v-model="group.groupId">
              <template v-for="(item, gidx) in groups">
                <Option :value="item.id" :key="gidx">{{item.name}}</Option>
              </template>
            </Select>
          </div>
          <div class="group-btns">
            <Button :disabled="idx == 0" type="primary" @click="up(idx)">↑</Button>
            <Button :disabled="idx == form.groups.length - 1" type="primary" @click="down(idx)">↓</Button>
            <Button type="error" @click="del(idx)">X</Button>
          </div>
        </div>
      </div>
    </FormItem>
    <FormItem>
      <Button type="primary" @click="saveSetting">保存配置</Button>
    </FormItem>
  </Form>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ConfigLampIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      form: {
        energyEnable: false, //是否启用每日能耗计算
        energyPrice: 1, //能耗电价
        energyOffset: 12, //能耗统计偏移小时数
        groups: [], //能耗统计分区设置，id及sort
      },
      groups: [],
      // selectedGroupIds: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
    
  },
  watch: {
    // 'form.groups'(){
    //   this.setGroupIds();
    // },
  },
  mounted: function () {
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    // setGroupIds: function(){
    //   let gids = [];
    //   this.form.groups.map(grp => {
    //     gids.push(grp.groupId);
    //   })
    //   this.$set(this, 'selectedGroupIds', gids);
    // },
    add: function(){
      let gids = [];
      this.form.groups.map(grp => {
        gids.push(grp.groupId);
      })
      let remains = this.groups.filter(p => !gids.includes(p.id));
      if(remains.length == 0){
        this.$Message.warning('没有可选分区了');
        return;
      }
      this.form.groups.push({groupId: remains[0].id, groupName: remains[0].name});
    },
    del: function(idx){
      this.form.groups.splice(idx, 1);
    },
    down: function(idx){
      // console.log('down idx', idx);
      if(idx == this.form.groups.length - 1)return;
      let results = this.form.groups.splice(idx, 1);
      this.form.groups.splice(idx + 1, 0, ...results);
    },
    up: function(idx){
      // console.log('up idx', idx);
      if(idx == 0)return;
      let results = this.form.groups.splice(idx, 1);
      this.form.groups.splice(idx - 1, 0, ...results);
    },
    getList: function () {
      this.$axios.post(`device/energy/QueryLightSetting`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'form', res.data.setting);
          this.$set(this, 'groups', res.data.groups);
        }
      });
    },
    saveSetting: function () {
      if(this.form.energyEnable && this.form.energyPrice < 0){
        this.$Message.warning('电价必须大于0');
        return false;
      }
      if(this.form.energyEnable && this.form.groups.length == 0){
        this.$Message.warning('请设置能耗统计分区');
        return false;
      }
      let groupIds = [];
      this.form.groups.map(p => {
        groupIds.push(p.groupId);
      })
      let args = {
        energyEnable: this.form.energyEnable, //是否启用每日能耗计算
        energyPrice: this.form.energyPrice, //能耗电价
        energyOffset: this.form.energyOffset, //能耗统计偏移小时数
        groups: groupIds, //能耗统计分区设置，id及sort
      }
      this.$axios.post(`device/energy/SaveLightSetting`, args).then(res => {
        if (res.code == 0) {
          this.$Modal.info({
            title: '保存能耗设置',
            content: '保存成功',
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.energy-group-list{
  /* border: solid 1px #dcdee2; */
  width: 500px;
  min-height: 100px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.energy-group-item{
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.group-index{
  width: 50px;
  flex: none;
  text-align: right;
  /* padding-right: 10px; */
}
.group-content{
  width: 100px;
  flex: auto;
  padding: 0 5px;
}
.group-btns{
  width: 135px;
  flex: none;
}
.group-btns button{
  margin-right: 5px;
}
.config-device-container {
  border: solid 1px #dcdee2;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* padding: 10px; */
  background-color: #F6F6FA;
  border-radius: 8px;
}
.config-tree-area{
  /* border: solid 1px #dcdee2; */
  width: 300px !important;
  flex: none;
  border-radius: 6px;
}
.device-data-area {
  /* margin-left: 10px; */
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.query-data-area {
  border-bottom: solid 1px #dcdee2;
  align-items: center;
  /* margin: 5px 0; */
  padding: 5px 0;
  height: 45px;
  flex: none;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding: 5px;
  /* padding-left: 10px; */
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.split-row-area{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item{
  /* border-bottom: solid 1px red; */
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
}
.split-row-flag{
  border-bottom: solid 1px #dcdee2;
}
</style>